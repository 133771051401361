@font-face {font-family: "Eina03-Light";
  src: url("../../../../javascript/assets/fonts/Eina03/Eina03-Light.ttf");
}

@font-face {font-family: "Eina03-Regular";
  src: url("../../../../javascript/assets/fonts/Eina03/Eina03-Regular.ttf");
}

@font-face {font-family: "Eina03-SemiBold";
  src: url("../../../../javascript/assets/fonts/Eina03/Eina03-SemiBold.ttf");
}

@font-face {font-family: "Eina03-Bold";
  src: url("../../../../javascript/assets/fonts/Eina03/Eina03-Bold.ttf");
}
