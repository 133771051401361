.navigation-button {
  width: 2.2rem;
  height: 2rem;

  &:not(.navbar-toggler) {
    left: 1rem;
    top: 3.8rem;
    margin: 0 1.6rem;
    position: fixed;
    z-index: 102;
  }

  .collapsed,
  div {
    height: 1px;
    width: 100%;
    border-radius: 40px;
    background-color: var(--secondary);
    overflow: hidden;
    transition: all 0.4s ease;
    position: absolute;
    top: 0;

    &:nth-child(2) {
      margin-top: 6px;
    }

    &:nth-child(3) {
      margin-top: 12px;
    }

    .public  & {
      background-color: var(--white);
    }
  }

  &.navbar-toggler:not(.collapsed),
  &--close {
    div {
      background-color: var(--white);

      &:nth-child(2) {
        overflow: visible;
      }
      &:nth-child(1) {
        transform: rotate(45deg) translateY(3px) translateX(3px);
      }
      &:nth-child(2) {
        background-color: transparent;
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translateY(-6px) translateX(5px);
      }
    }
  }
}
