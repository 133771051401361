.btn {
  &.btn-primary {
    @include button-variant(map-get($theme-colors, "primary"), transparent, darken(map-get($theme-colors, "primary"), 3%), transparent, darken(map-get($theme-colors, "primary"), 3%), #fff);
  }

  &.btn-default {
    @include button-variant(#fff, map-get($theme-colors, "light-gray"), #fff, darken(map-get($theme-colors, "light-gray"), 10%), #fff, darken(map-get($theme-colors, "light-gray"), 10%));
  }

  &.btn-square {
    width: 4rem;
    height: 4rem;
    padding: 1rem;
  }

  &.btn-sm {
    svg {
      height: 1.2em;
      width: 1.2em;
      vertical-align: middle;
    }
  }

  &.btn-xs {
    height: 4rem;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &-link {
      &:focus {
        box-shadow: none;
      }
  }

  &.suspended,
  &.btn-disabled,
  &.disabled {
    opacity: .48;
    pointer-events: none;
  }

  &--manually-verify {
    padding: 9px 13px !important;
  }

  &--trustly-register {
    max-width: 20.6rem;
    width: 100%;
  }

  &:has(.loading) {
    position: relative;
  }
}

.btn-next,
.btn-back {
  svg {
    transition: transform 0.3s ease-out;
  }

  &:hover {
    svg {
      transform: translateX(4px);
    }
  }
}

.btn-next,
.btn-back {
  svg {
    transition: transform 0.3s ease-out;
  }
}


.btn-next {
  &:hover {
    svg {
      transform: translateX(4px);
    }
  }
}

.btn-back {
  &:hover {
    svg {
      transform: translateX(-4px);
    }
  }
}

/* Modifiers */
.btn-fileUpload {
  display: inline-table;
  overflow: hidden;
  position: relative;
  padding-top: 0.55em;
  padding-bottom: 0.6em;
  background-color: var(--primary);
  color: var(--primary);

  input {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

@include media-breakpoint-down(xs) {
  .btn {
    &.process {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
