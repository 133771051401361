//BODY
$font-family: (
  "regular": Eina03-Regular,
  "light": Eina03-Light,
  "semibold": Eina03-SemiBold,
  "bold": Eina03-Bold
);

$body-bg: #fff;
$body-alternative-bg: #f2f5f7;

//CARDS
$card-border-width: 1px;

$font-family-sans-serif: map-get($font-family, "regular"), "Lucida Grande", Helvetica, Arial, sans-serif;
$input-font-family: map-get($font-family, "semibold"), "Lucida Grande", Helvetica, Arial, sans-serif;
