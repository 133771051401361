@import 'colorsMixins';

$theme-colors: (
  "primary": #03c5d3,
  "secondary": #00215d,
  "danger": #de006a,
  "warning": #ffd92c,
  "info": #00c5d2,
  "light": #e1e5e7,
  "gray": #8e91a2,
  "light-gray": #dbdce4,
  "lighter-gray": #f1f2f6,
  "pale-primary": #f0feff,
  "dark": #00215d,
  "lighter-primary": #e4fcfe,
  "black": #19164f,
  "bronze": #dfa979,
  "silver": #91909a,
  "gold": #f0c52c
);

$font-family: (
  "regular": NunitoSans-Regular,
  "light": NunitoSans-Light,
  "semibold": NunitoSans-SemiBold,
  "bold": NunitoSans-Bold
);

//BADGES
$badge-border-radius: 2.8rem;
$badge-font-size: 1.2rem;
$badge-font-weight: 100;
$badge-padding-y: .9rem;
$badge-padding-x: 1.6rem;
$badge-pill-padding-x: 1.6rem;

//BORDERS
$border-radius: .7rem;

//BREADCRUMBS
$breadcrumb-bg: initial;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 0;
$breadcrumb-font-size: 1.3rem;
$breadcrumb-active-color: var(--gray);
$breadcrumb-divider-color: $breadcrumb-active-color;
$breadcrumb-divider: '\f105';

//BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1681px
);

//BODY
$body-bg: #f2f5f7;
$body-color: #19164f;

//BUTTONS
$btn-padding-y: 1.1rem;
$btn-padding-x: 2.4rem;
$btn-padding-y-sm: .9rem;
$btn-padding-x-sm: 2rem;
$btn-border-radius: var(--border-radius-sm);
$btn-border-radius-sm: var(--border-radius-sm);

$btn-padding-y-sm: 0.8rem;
$btn-padding-x-sm: 1.1rem;
$btn-line-height-sm: 1.6;

//BREADCRUMBS
$breadcrumb-bg: initial;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 0;

//CARDS
$card-border-radius: var(--border-radius);
$card-border-width: 0;
$card-spacer-y: 0;
$card-spacer-x: 2.4rem;
$card-cap-color: map-get($theme-colors, "secondary");
$card-cap-bg: transparent;

//DROPDOWN
$dropdown-border-radius: var(--border-radius-sm);
$dropdown-border-color: var(--lighter-gray);
$dropdown-link-hover-bg: var(--pale-primary);
$dropdown-link-active-bg: var(--pale-primary);
$dropdown-link-active-color: var(--dark);
$dropdown-font-size: inherit;


//FONT
$font-size-base: 1.6rem;
$font-family-sans-serif: map-get($font-family, "regular"), "Lucida Grande", Helvetica, Arial, sans-serif;

//FORMS
$form-group-margin-bottom: 2rem;

$custom-control-indicator-size: 2.3rem;
$custom-control-indicator-border-color: map-get($theme-colors, "light-gray");
$label-margin-bottom: .3rem;

$input-font-family: map-get($font-family, "semibold"), "Lucida Grande", Helvetica, Arial, sans-serif;
$input-line-height: 2.4;
$input-font-size: 1.6rem;
$input-border-radius: var(--border-radius-sm);
$input-border-radius-sm: var(--border-radius-sm);
$input-border-color: map-get($theme-colors, "light-gray");
$input-font-family: map-get($font-family, "semibold"), "Lucida Grande", Helvetica, Arial, sans-serif;
$input-line-height: 2.4;
$input-focus-box-shadow: revert;
$input-focus-border-color: var(--primary);
$input-color: map-get($theme-colors, "secondary");

$input-border-color-sm: map-get($theme-colors, "light-gray");

//LINKS
$link-color: map-get($theme-colors, "primary");
$link-hover-color: map-get($theme-colors, "primary");

//MEDIA QUERY
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1140px,
  xl: 1440px
);

//MODALS
$modal-dialog-margin: 7vh;
$modal-dialog-margin-y-sm-up: $modal-dialog-margin;
$modal-content-border-radius: var(--border-radius);
$modal-header-padding: 1.6rem 2.4rem;
$modal-inner-padding: 2.4rem;
$modal-footer-border-width: 0;

//NAVBAR
$navbar-dark-color: var(--white);
$navbar-dark-hover-color: var(--white);
$navbar-light-color: var(--dark);
$navbar-light-hover-color: var(--dark);
$navbar-padding-y: 1.8rem;
$navbar-padding-x: 2rem;

$nav-tabs-border-radius: none;
$nav-tabs-link-active-border-color: var(--white);
$nav-tabs-link-active-bg: var(--secondary);
$nav-tabs-link-active-color: var(--white);
$nav-tabs-border-width: 0;

$nav-link-padding-x: 1rem;
$nav-link-color: var(--white);
$nav-pills-link-active-bg: color("secondary", $lightness: -5%);
$nav-pills-border-radius: $btn-border-radius;

$navbar-brand-padding-y: 0.5rem;

//POPOVERS
$popover-header-bg: #fff;
$popover-header-padding-y: 2rem;
$popover-header-padding-x: 2.4rem;
$popover-border-radius: var(--border-radius);
$popover-max-width: 65rem;

//SIZES
$sizes: (
  15: 15%,
  30: 30%
);

//SPACERS
$spacers: (
  6: 6rem,
  7: 7rem,
  8: 8rem,
  9: 9rem,
  10: 10rem,
);

//TABLES
$table-border-color: color("lighter-gray", $lightness: -3.5%);
$table-cell-padding: 1.2rem .75rem;
$table-hover-bg: map-get($theme-colors, "pale-primary");
$table-striped-order: even;
$table-accent-bg: map-get($theme-colors, "pale-primary");

//TOOLTIPS
$tooltip-font-size: 1.2rem;

//MISCELLANEOUS
$box-shadow: 0 4px 6px rgba(50, 50, 93, 0.08);

//OVERFLOWS
$overflows: auto, hidden, initial;

//DATEPICKER
$datepicker__font-size: 1.5rem;
$datepicker__item-size: 3.5rem;
$datepicker__background-color: var(--white);
$datepicker__border-color: var(--light-gray);
$datepicker__border-radius: var(--border-radius);
$datepicker__muted-color: map-get($theme-colors, "gray");
$datepicker__selected-color: map-get($theme-colors, "primary");
