.public,
.public-page {
  .billboard-aside {
    position: relative;
    background: transparent !important;

    & > .container {
      @include media-breakpoint-down(lg) {
        padding: 0;
      }
    }

    .partners {
      img {
        height: 3.7rem;
        margin: 2.5rem;
        filter: invert(82%) sepia(45%) saturate(202%) hue-rotate(192deg) brightness(87%) contrast(107%);
        opacity: .57;
      }
    }

    &-content {
      display: flex;
      width: 100%;
      align-items: center;
      height: 100%;

    }

    &:before {
      position: absolute;
      content: "";
      display: block;
      height: 100%;
    }

    &-left {
      &:before {
        left: 0;
        width: calc(98% - 6rem);
        border-top-right-radius: 60rem;
        border-bottom-right-radius: 60rem;

        @include media-breakpoint-down(lg) {
          width: 100% !important;
          border-radius: initial;
        }
      }

      img:not(.phones) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-right {
      &:before {
        width: calc(50% - 6rem);
        right: 0;
        border-top-left-radius: 60rem;
        border-bottom-left-radius: 60rem;

        @include media-breakpoint-down(lg) {
          width: 100% !important;
          border-radius: initial;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      &:not(.bg-lighter-gray) {
        color: var(--white);

        h2 {
          &:before {
            background: var(--white);
          }
        }
      }

      h2 {
        &:before {
          margin-top: 5rem;
        }
      }
    }

    &.bg-lighter-gray {
      &:before {
        background-image: url('../../../javascript/assets/images/public/crown-semi-transparent.png'), url('../../../javascript/assets/images/public/ezeewallet-semi-transparent.png');
        background-color: var(--lighter-gray);
        background-repeat: no-repeat, no-repeat;
        background-position: 0 100%, 100% 0;
      }
    }

    &.bg-secondary {
      &:before {
        background: var(--secondary);
      }
    }

    img {
      z-index: 1;
    }
  }
}
