.public-page,
.public {
  font-size: 1.8rem;

  &:not(.home-page) {
    background: $body-alternative-bg;
  }

  h1 {
    &.h2 {
      @extend %header-semi-bold;
    }
  }

  h2:not(.h3) {
    font-size: 6.1rem;
    text-align: left;
    margin-bottom: 5.5rem;
    font-family: var(--font-family-sans-serif);
    position: relative;

    @include media-breakpoint-down(sm) {
      font-size: 5.1rem;
    }

    &:before {
      content: "";
      display: block;
      background: var(--secondary);
      width: 9rem;
      padding: 1px;
      position: absolute;
      top: 3rem;
      left: -11rem;

      @include media-breakpoint-down(lg) {
        position: static;
        margin-bottom: 2rem;
      }
    }

    &.h3 {
      font-family: var(--font-family-sans-serif-semibold);
      font-size: 2.2rem;
    }

    span {
      font-family: var(--font-family-sans-serif);
      font-size: 1.6rem;
      display: block;
      margin-top: 1rem;
    }
  }

  h3,
  .h3 {
    font-size: 2.4rem;
    color: var(--secondary);
    font-family: var(--font-family-sans-serif-semibold);
  }

  h5 {
    font-family: var(--font-family-sans-serif-semibold);
  }

  &.home-page {
    .card {
      box-shadow: none;
    }
  }

  .card {
    border: none;
  }

  .feature-icon {
    height: 100%;
    max-width: 8rem;
    max-height: 6.5rem;
    margin-bottom: 3rem;
  }

  .features-body-content {
    h3 {
      font-size: 27px;
      color: #fff;
      margin-bottom: 40px;
    }

    p {
      font-size: 18px;
      line-height: 30px;
      color: var(--secondary);
    }

    a {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  section {
    margin-top: 20rem;

    @include media-breakpoint-down(md) {
      margin-top: 10rem;
    }
  }

  .vip-silver {
    color: var(--silver);
  }

  .vip-bronze {
    color: var(--bronze);
  }

  .vip-gold {
    color: var(--gold);
  }

  .under-review-message {
    img {
      max-width: 15rem;
    }
  }

  .legal-documents {
    p,
    ol,
    ul {
      margin-bottom: 3.5rem;

      li {
        margin-bottom: 1.2rem;
      }
    }

    ol {
      list-style: lower-alpha;
    }

    strong {
      font-family: var(--font-family-sans-serif-semibold);
    }
  }
}
