.payment-option-wrapper {
  .payment-option {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    font-size: 0;
    padding: 4rem 6rem;
    text-align: center;
    vertical-align: middle;
    width: 10rem;
    margin: 0 0 1.5rem;
  }

  .content-title {
    line-height: 200%;
    color: var(--secondary);
    font-size: 1.8rem;
    font-family: var(--font-family-sans-serif);
  }

  .radio {
    height: 100%;
    margin: 0;
    width: 100%;
  }

  input {
    visibility: hidden;
  }

  .content,
  label {
    position: relative;
    align-items: center;
    color: var(--gray);
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-sans-serif);
    justify-content: center;
    height: 100%;
    padding: 0;
    text-align: center;
  }

  .payment-option__details {
    align-items: center;
    background: #00215D;
    will-change: transform;
    transform: translateY(154px);
    box-shadow: 0px -2px 6px transparent;
    color: #cecece;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    height: 90px;
    justify-content: center;
    position: absolute;
    width: 100%;
    transition: all .5s;
    font-size: 1.2rem;

    strong {
      color: #fff;
      display: inline-block;
      font-family: var(--font-family-sans-serif-semibold);
      font-size: 1.2rem;
      text-align: left;
      width: 60%;
    }

    p {
      font-size: 0;
      line-height: 12px;
      margin: 0;
      vertical-align: top;
      width: 100%;
    }

    .details-title {
      color: transparentize(#fff, 0.45%);
      font-family: var(--font-family-sans-serif);
      padding-right: 5px;
      width: 40%;
      display: inline-block;
      text-align: right;
      font-size: 1.1rem;
    }
  }

  &:hover {
    .payment-option__details {
      transform: translateY(34px);
      box-shadow: 0px -2px 6px #00000014;
    }
  }
}
