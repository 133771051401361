.argencard {
  background-image: url('../../../../javascript/assets/images/logos/argencard.svg');
}

.aura {
  background-image: url('../../../../javascript/assets/images/logos/aura.svg');
}

.payid,
.latipay {
  background-image: url('../../../../javascript/assets/images/logos/payid.svg');
}

.baloto {
  background-image: url('../../../../javascript/assets/images/logos/baloto.svg');
}

.banco_do_brasil {
  background-image: url('../../../../javascript/assets/images/logos/banco-do-brasil.svg');
}

.bancomer {
  background-image: url('../../../../javascript/assets/images/logos/bancomer.svg');
}

.bank {
  background-image: url('../../../../javascript/assets/images/logos/bank.svg');
}

.bc {
  background-image: url('../../../../javascript/assets/images/logos/bc.svg');
}

.blk {
  background-image: url('../../../../javascript/assets/images/logos/blk.svg');
}

.bp {
  background-image: url('../../../../javascript/assets/images/logos/bp.svg');
}

.bq {
  background-image: url('../../../../javascript/assets/images/logos/bq.svg');
}

.bcmc {
  background-image: url('../../../../javascript/assets/images/logos/bcmc.svg');
}

.boleto {
  background-image: url('../../../../javascript/assets/images/logos/boleto.svg');
}

.bradesco {
  background-image: url('../../../../javascript/assets/images/logos/bradesco.svg');
}

.ca {
  background-image: url('../../../../javascript/assets/images/logos/caixa.svg');
}

.cash_711 {
  background-image: url('../../../../javascript/assets/images/logos/cash-711.svg');
}

.cabal {
  background-image: url('../../../../javascript/assets/images/logos/cabal.svg');
}

.cencosud {
  background-image: url('../../../../javascript/assets/images/logos/cencosud.svg');
}

.cordial {
  background-image: url('../../../../javascript/assets/images/logos/cordial.svg');
}

.cordobesa {
  background-image: url('../../../../javascript/assets/images/logos/cordobesa.svg');
}

.davivienda {
  background-image: url('../../../../javascript/assets/images/logos/davivienda.svg');
}

.elo {
  background-image: url('../../../../javascript/assets/images/logos/elo.svg');
}

.ef {
  background-image: url('../../../../javascript/assets/images/logos/ef.svg');
}

.efecty {
  background-image: url('../../../../javascript/assets/images/logos/efecty.svg');
}

.eps {
  background-image: url('../../../../javascript/assets/images/logos/eps.svg');
}

.fpx_abb {
  background-image: url('../../../../javascript/assets/images/logos/fpx-abb.svg');
}

.fpx_abmb {
  background-image: url('../../../../javascript/assets/images/logos/fpx-abmb.svg');
}

.fpx_amb {
  background-image: url('../../../../javascript/assets/images/logos/fpx-amb.svg');
}

.fpx_bimb {
  background-image: url('../../../../javascript/assets/images/logos/fpx-bimb.svg');
}

.fpx_bkrm {
  background-image: url('../../../../javascript/assets/images/logos/fpx-bkrm.svg');
}

.fpx_bmmb {
  background-image: url('../../../../javascript/assets/images/logos/fpx-bmmb.svg');
}

.fpx_bsn {
  background-image: url('../../../../javascript/assets/images/logos/fpx-bsn.svg');
}

.fpx_cimbclicks {
  background-image: url('../../../../javascript/assets/images/logos/fpx-cimbclicks.svg');
}

.fpx_hlb {
  background-image: url('../../../../javascript/assets/images/logos/fpx-hlb.svg');
}

.fpx_mb2u {
  background-image: url('../../../../javascript/assets/images/logos/fpx-mb2u.svg');
}

.fpx_ocbc {
  background-image: url('../../../../javascript/assets/images/logos/fpx-ocbc.svg');
}

.fpx_pbb {
  background-image: url('../../../../javascript/assets/images/logos/fpx-pbb.svg');
}

.fpx_rhb {
  background-image: url('../../../../javascript/assets/images/logos/fpx-rhb.svg');
}

.fpx_scb {
  background-image: url('../../../../javascript/assets/images/logos/fpx-scb.svg');
}

.fpx_uob {
  background-image: url('../../../../javascript/assets/images/logos/fpx-uob.svg');
}

.giropay {
  background-image: url('../../../../javascript/assets/images/logos/giropay.svg');
}

.hipercard {
  background-image: url('../../../../javascript/assets/images/logos/hipercard.svg');
}

.ib {
  background-image: url('../../../../javascript/assets/images/logos/ib.svg');
}

.ideal {
  background-image: url('../../../../javascript/assets/images/logos/ideal.svg');
}

.itau {
  background-image: url('../../../../javascript/assets/images/logos/itau.svg');
}

.mastercard {
  background-image: url('../../../../javascript/assets/images/logos/mastercard.svg');
}

.multibanco {
  background-image: url('../../../../javascript/assets/images/logos/multibanco.svg');
}

.my_bank {
  background-image: url('../../../../javascript/assets/images/logos/mybank.svg');
}

.nb {
  background-image: url('../../../../javascript/assets/images/logos/nb.svg');
}

.naranja {
  background-image: url('../../../../javascript/assets/images/logos/naranja.svg');
}

.nativa {
  background-image: url('../../../../javascript/assets/images/logos/nativa.svg');
}

.neosurf {
  background-image: url('../../../../javascript/assets/images/logos/neosurf.svg');
}

.oxxo {
  background-image: url('../../../../javascript/assets/images/logos/oxxo.svg');
}

.p24 {
  background-image: url('../../../../javascript/assets/images/logos/p24.svg');
}

.pago_facil {
  background-image: url('../../../../javascript/assets/images/logos/pago_facil.svg');
}

.paysafecard {
  background-image: url('../../../../javascript/assets/images/logos/paysafecard.svg');
}


.payu {
  background-image: url('../../../../javascript/assets/images/logos/payu.svg');
}

.pix {
  background-image: url('../../../../javascript/assets/images/logos/pix.svg');
}

.poli {
  background-image: url('../../../../javascript/assets/images/logos/poli.svg');
}

.pse {
  background-image: url('../../../../javascript/assets/images/logos/pse.svg');
}

.qiwi {
  background-image: url('../../../../javascript/assets/images/logos/qiwi.svg');
}

.rapi_pago {
  background-image: url('../../../../javascript/assets/images/logos/rapipago.svg');
}

.safetypay {
  background-image: url('../../../../javascript/assets/images/logos/safetypay.svg');
}

.santander {
  background-image: url('../../../../javascript/assets/images/logos/santander.svg');
}

.se {
  background-image: url('../../../../javascript/assets/images/logos/se.svg');
}

.sp {
  background-image: url('../../../../javascript/assets/images/logos/sp.svg');
}

.sdd_sale {
  background-image: url('../../../../javascript/assets/images/logos/sepa.svg');
}

.sofort {
  background-image: url('../../../../javascript/assets/images/logos/sofort.svg');
}

.tarjeta,
.tarjeta_shopping {
  background-image: url('../../../../javascript/assets/images/logos/tarjeta_shopping.svg');
}

.trustly_sale {
  background-image: url('../../../../javascript/assets/images/logos/trustly-sale.svg');
}

.upi {
  background-image: url('../../../../javascript/assets/images/logos/upi.svg');
}

.visa {
  background-image: url('../../../../javascript/assets/images/logos/visa.svg');
}

.rupay {
  background-image: url('../../../../javascript/assets/images/logos/rupay.svg');
}

.webpay {
  background-image: url('../../../../javascript/assets/images/logos/webpay.svg');
}

.bitpay_sale {
  background-image: url('../../../../javascript/assets/images/logos/bitpay.svg');
}

