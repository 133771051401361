.card,
.panel {
  box-shadow: 0 4px 4px 0 rgba(50, 50, 93, 0.08);
  margin-bottom: 24px;

  .card-header,
  .panel-heading {
    border-bottom: 1px solid color("lighter-gray", $lightness: -3.5%);
  }

  .panel-heading,
  .panel-body,
  .panel-footer,
  .card-footer {
    padding: 24px;
  }

  header,
  .card-header,
  .panel-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 1.6rem;
      font-family: var(--font-family-sans-serif-semibold);

      & > div {
        font-family: var(--font-family-sans-serif);
      }
    }

    :not(i):not(h1) {
      margin: 0;
    }
  }

  h1 {
    &:not(.element-placeholder--title) {
      @extend %header-semi-bold;
    }

    &.card-header {
      font-size: 2.4rem;
      line-height: 3rem;
      font-family: var(--font-family-sans-serif-bold);
    }
  }

  h2 {
    &.card-header {
      font-size: 1.7rem;
      line-height: 2.6rem;
      font-family: var(--font-family-sans-serif-semibold);
    }
  }

  .card-footer,
  .panel-footer {
    background: transparent;
    border-color: color("lighter-gray", $lightness: -3.5%);
  }

  .public & {
    .sign-in {
      header,
      .card-header,
      .panel-heading {
        font-family: var(--font-family-sans-serif-bold);
      }
    }
  }
}

.tabs {
  .card,
  .panel {
    box-shadow: none;
  }
}

@media only screen and (max-width: 991.98px) {
  .card,
  .panel {
    border: none;
  }
}
