@import '../../../../../assets/stylesheets/common/config/colorsMixins';

.alerts-container {
  margin-top: 7rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10002;
}

.alert,
.message {
  color: var(--secondary);
  background: transparent;
  clear: both;
  display: table;
  position: relative;
  margin: 0 0 30px;
  padding: 2rem 2rem 2rem 7rem;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  max-width: 936px;

  p {
    margin: 0;
    padding: 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: 1rem;
    width: 2em;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.alert-danger {
    border-color: var(--danger);

    &:before {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path fill="%23de006a" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>');
    }
  }

  &.alert-warning {
    border-color: color('warning', $lightness: -10%);

    &:before {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path fill="%23e0a800" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>');
    }
  }

  &.alert-success {
    border-color: var(--info);

    &:before {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path fill="%2300c5d2" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>');
    }
  }

  &.alert-info {
    border-color: var(--primary);

    &:before {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path fill="%2303c5d3" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>');
    }
  }

  &.alert-inline {
    color: #fff;
    background: var(--primary);
    padding: 1rem;
    font-size: 1.6rem;
    z-index: 10;
  }

  &.is-hidden {
    display: none;
  }

  &.alert-over {
    display: block;
    right: 0;
    margin: 0 1rem 1rem 1rem;
    top: 0;
    position: relative;
    z-index: 10000;
    animation: 3s slide-down;
    min-width: 30rem;
    max-width: 60rem;
    padding: 0;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 0 4px 4px 0 rgba(50, 50, 93, 0.08);
    background-color: transparentize(#fff, .16);
    backdrop-filter: blur(4px);
    max-height: 100%;
    border: none;

    &:before {
      top: 2.2rem;
    }

    p {
      padding: 2rem 7rem;
      border-radius: 7px;
      border-width: 1px;
      border-style: solid;
    }

    .progress-bar {
      width: 100%;
      height: .75rem;
      position: absolute;
      bottom: 0;
    }

    &.alert-success {
      .progress-bar {
        background: color('info', $alpha: .85);
      }

      p {
        border-color: var(--info);
      }
    }

    &.alert-danger {
      .progress-bar {
        background: color('danger', $alpha: .85);
      }

      p {
        border-color: var(--danger);
      }
    }

    &.alert--close-animation {
      animation: 1s slide-right forwards;
    }
  }
}

.prepaid-terms {
  .message-content {
    .message-link {
      color: #fff;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.myAccount {
  .message-content {
    ul {
      display: inline;

      li {
        display: block;
        list-style: none outside none;
      }
    }
  }
}

.request-verification {
  margin-bottom: 20px;
}

.home-page {
  position: relative;

  .message {
    color: #fff;
    margin: 0 auto;
  }

  .app-wrapper {
    background: var(--secondary);
  }
}

.no-data-message {
  font-family: var(--font-family-sans-serif-semibold);
  padding: 5rem 0;
  color: color('gray', $lightness: 15%)
}

@keyframes slide-down {
  0% {
    opacity: 0;
    top: -7rem;
  }

  12% {
    opacity: 1;
    top: 0;
  }

  97% {
    opacity: 1;
    top: 0;
  }
}

@keyframes slide-right {
  0% {
    opacity: 1;
    transform: translateX(0);
    max-height: 50rem;
    filter: blur(0);
  }

  70% {
    opacity: 0;
    transform: translateX(4rem);
    max-height: 50rem;
    filter: blur(.5rem);
  }

  71% {
    opacity: 0;
    transform: translateX(4rem);
    max-height: 50rem;
  }

  100% {
    opacity: 0;
    transform: translateX(4rem);
    max-height: 0;
  }
}

@media only screen and (max-width : 1200px) {
  .home-page {
    .message {
      width: 700rem;
      margin-left: -35rem;
    }
  }
}

@media only screen and (max-width : 768px) {
  .home-page {
    .message {
      width: 40rem;
      margin-left: -20rem;
    }
  }
}

@media only screen and (max-width : 500px) {
  .home-page {
    .message {
      width: 30rem;
      margin-left: -15rem;
    }
  }
}
