@import '../../../../../../../assets/stylesheets/common/config/customBootstrap';
@import '~bootstrap/scss/mixins/breakpoints';

.billboard {
  width: 100%;
  position: relative;
  overflow: hidden;
  color: var(--white);
  padding-top: 15rem;

  h1 {
    font-size: 6.1rem;
    line-height: 6.6rem;
    font-weight: normal;
    font-family: var(--font-family-sans-serif);

    @include media-breakpoint-down(md) {
      font-size: 5.1rem;
    }
  }

  p {
    font-size: 1.8rem;
    margin: 1rem 0 0;
  }

  &.bg-lighter-gray {
    background: transparent !important;

    &:before {
      background: var(--lighter-gray) !important;
    }
  }

  &:before {
    border-radius: 100%;
    position: absolute;
    background: var(--secondary);
    right: -70rem;
    left: -70rem;
    top: -60rem;
    content: '';
    bottom: 0;
    z-index: -1;

    @include media-breakpoint-down(lg) {
      right: -80rem;
      left: -80rem;
    }
  }

  &-partners {
    img {
      margin: 0 4.5rem 4rem 0;
      height: 3.7rem;
      filter: invert(82%) sepia(45%) saturate(202%) hue-rotate(192deg) brightness(87%) contrast(107%);
      opacity: .67;
      position: initial;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &-image {
    width: 100%;
  }

  &-with-content-padding-bottom {
    .billboard-content {
      padding-bottom: 10rem;
    }
  }
}
