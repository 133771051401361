:root {
  @include define-color("primary", map-get($theme-colors, "primary"));
  @include define-color("secondary", map-get($theme-colors, "secondary"));
  @include define-color("warning", map-get($theme-colors, "warning"));
  @include define-color("pale-primary", map-get($theme-colors, "pale-primary"));
  @include define-color("lighter-gray", map-get($theme-colors, "lighter-gray"));
  @include define-color("gray", map-get($theme-colors, "gray"));
  @include define-color("danger", map-get($theme-colors, "danger"));
  @include define-color("info", map-get($theme-colors, "info"));
}

:root {
  --font-family-sans-serif-light: #{inspect(map-get($font-family, "light"))}, "Lucida Grande", Helvetica, Arial, sans-serif;
  --font-family-sans-serif-semibold: #{inspect(map-get($font-family, "semibold"))}, "Lucida Grande", Helvetica, Arial, sans-serif;
  --font-family-sans-serif-bold: #{inspect(map-get($font-family, "bold"))}, "Lucida Grande", Helvetica, Arial, sans-serif;
}

:root {
  --navigation-width: 26.4rem;
  --border-radius: 1rem;
  --border-radius-sm: .6rem;
}
