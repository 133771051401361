@include card('card-bank-account', 426px, auto);

.payment-cards-wrapper,
.cards-wrapper {
  display: flex;

  &:not(.cards-wrapper--deposit) {
    flex-wrap: wrap;
  }

  @include card('card-ezeewallet', 320px, 128px);
  @include card('card-credit-card', 23.5rem, 13.5rem);
  @include card('user-account', 426px, auto);
  @include card('payment-option-wrapper', 18rem, 16rem);
  @include card('apm-wrapper', 160px, 160px);
  @include card('payment-card-wrapper--bank', 320px, 164px);
  @include card('card-wrapper--pending-transfer', 438px, auto);
  @include card('card-wrapper--pending-transfer', 438px, auto);

  .card-ezeewallet,
  .apm-wrapper,
  .payment-option-wrapper,
  .payment-card-wrapper {
    box-shadow: none;
  }

  &--merchant-bank-account,
  &--merchant-employees {
    .btn-square{
      width: 100%;
      text-align: left;
      border: none;
      padding-left: 1.6rem;
    }
  }

  &.expired {
    pointer-events: none;
  }
}

.payment-option-wrapper {
  .content-title {
    font-size: 1.8rem;
    line-height: 30;
    color: var(--secondary);
    text-transform: capitalize;
  }

  &:hover {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .18);
    background: initial !important;
  }
}

.payment-card-wrapper,
.card-wrapper {
  background-repeat: no-repeat;
  background-position: 0 100%;

  .payment-cards-wrapper &,
  .cards-wrapper & {
    border-radius: 10px;
    input {
      margin: 0;
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }

  .logo {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    height: 2.5rem;
    text-align: center;
    flex-shrink: 0;

    &.nativa,
    &.argencard {
      background-color: #fff;
      border-radius: 3px;
      border: 2px solid #fff;
    }
  }

  .name {
    margin: 0;
    color: rgba(0, 0, 0, 0.05);
    letter-spacing: -1px;
    font-size: 2.5rem;
  }

  .expiration-date,
  .current-user {
    text-transform: uppercase;
    margin-top: 2px;
    font-weight: bold;
    color: #fff;
    font-size: 1.8rem;
  }

  .current-user {
    text-transform: uppercase;
  }

  .expiration-date {
    font-size: 1.6rem;
    font-weight: normal;
    opacity: .6;
  }

  .content,
  .value {
    color: var(--secondary);
    font-family: var(--font-family-sans-serif-bold);
    letter-spacing: -1px;
    font-size: 22px;
  }

  .radio {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  &.card-credit-card {
    background: #0F184B linear-gradient(315deg, #12258B, #0F184B);
    transition: .5s;

    &:hover {
      transition: .5s;
      box-shadow: 0 4px 6px rgba(50, 50, 93, .18);
      background: var(--primary);

      label {
        cursor: pointer;
      }

      .card-number {
        color: #fff;
      }
    }

    span {
      display: inline-block;
      width: 100%;
    }

    &.new-card {
      background: var(--primary);
      width: 9rem;

      i, svg {
        color: #fff;
        font-size: 2.8rem;
        margin-bottom: 5px;
      }

      .name {
        color: #fff;
        font-size: 1.8rem;
        font-family: var(--font-family-sans-serif-semibold);
        line-height: 2.6rem;
      }
    }

    .card-number {
      color: var(--primary);
      font-family: var(--font-family-sans-serif-semibold);
      letter-spacing: 1px;
      line-height: 30px;
    }

    .ezeewallet {
      height: 2rem;
      background-position: 0;
      width: 100%;
    }

    .card-brand {
      width: 4.6rem;
    }

    .visa {
      background-image: url('../../../../javascript/assets/images/logos/visa.svg');
    }

    &.element-placeholder--row {
      max-width: 22rem;
      background: initial;

      .element-placeholder--card {
        height: 100%;
      }
    }
  }

  &.card-ezeewallet,
  &.user-account {
    &:hover {
      cursor: revert;
    }

    .verification-status {
      position: absolute;
      right: 16px;
      top: 16px;
    }

    .card-icon {
      background: var(--pale-gray);

      &--user-account {
        position: relative;
        background: var(--secondary);
        color: #fff;
        width: 56px;
        height: 56px;
        font-size: 32px;
        flex-shrink: 0;
      }
    }

    .bank-number,
    .card-number {
      span {
        letter-spacing: 1px;
      }
    }
  }

  &.user-account {
    border: none;
  }

  &--bank {
    .value {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .card-utils {
    position: absolute;
    right: 24px;
  }
}

.payment-card-wrapper {
  label{
    height: 100%;
    padding: 1.6rem;
    display: flex;
    align-items: center;
  }
}

.saved-cards {
  width: calc(100% - 12rem);
}

.user-account {
  &__email {
    font-size: 14px;
    line-height: 21px;
  }

  &__locked-status {
    position: absolute;
    bottom: -29px;
    font-family: var(--font-family-sans-serif-bold);
  }

  .fa-pulse,
  .fa-lock {
    background: #1c2970c7;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-link {
    color: var(--black);

    &:hover {
      text-decoration: none;
    }
  }

  .btn-link {
    text-align: left;
  }
}

.card {
  &--fx-rates {
    min-width: 43rem;
  }

  &-header {
    min-height: 6rem;

    h2 {
      overflow: hidden;
    }
  }
}


@include media-breakpoint-down(xs) {
  .payment-cards-wrapper,
  .cards-wrapper {
    .card-wrapper,
    .card-credit-card.new-card,
    .payment-card-wrapper,
    .payment-option-wrapper {
      width: 100%;
      max-width: 100%;
      margin-right: initial;
    }
  }
}

@include media-breakpoint-down(xs) {
  .payment-card-wrapper--bank,
  .card-wrapper--bank {
    .value {
      max-width: 10rem;
      word-break: break-all;
    }
  }

  .cards-wrapper, .cards-wrapper--deposit {
    display: block;
  }

  .saved-cards {
    display: block;
    width: 100%;
  }

  .card-wrapper.card-credit-card.new-card {
    width: auto;
  }
}
