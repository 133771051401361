@mixin define-color($title, $color) {
  --#{$title}-h: #{hue($color)};
  --#{$title}-l: #{lightness($color)};
  --#{$title}-s: #{saturation($color)};
  --#{$title}-a: #{alpha($color)};
}

@function color($title, $hue: 0deg, $lightness: 0%, $saturation: 0%, $alpha: 0) {
  @return hsla(
    calc(var(--#{$title}-h) + #{$hue}),
    calc(var(--#{$title}-s) + #{$saturation}),
    calc(var(--#{$title}-l) + #{$lightness}),
    calc(var(--#{$title}-a) - #{$alpha})
  );
}
