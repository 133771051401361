.dropdown {
  &-toggle {
    &:after {
      content: '';
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--><path fill="%2319164f" d="M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"/></svg>');
      display: inline-block;
      height: 1.4rem;
      width: 1.4rem;
      margin-left: 1rem;
      border: 0;
      vertical-align: initial;
    }
  }

  &-menu {
    animation: slide-up-dropdown-menu .3s;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1);
  }

  #language,
  #language + [aria-labelledby="language"] a {
    font-family: var(--font-family-sans-serif-semibold);
  }

  [aria-labelledby="my-account-dropdown"]  {
    min-width: 16rem;
    left: auto;
    right: 0;
  }

  #my-account-dropdown {
    @media only screen and (max-width : 480px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 8rem;
    }
  }
}

@keyframes slide-up-dropdown-menu {
  0% {
    top: 120%;
    opacity: 0
  }
  100% {
    top: 100%;
    opacity: 1
  }
}
